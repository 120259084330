<!-- Ventana para mostrar Mensajes Generales / Particulares -->

<template>
  <div class="msgView">   
    <dualTemplate
      dualTipo='modal'
      :modal=true      
       persistent>
      
        <template v-slot:controles="{}">

          <div>  

            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                titulo='Avisos'
                @onEvent="$emit('onSalir')"> 
              </baseHeader>
            </div> 

      
            <v-sheet :elevation="4">
              <div class="contenedor">
                
                <div style="padding:20px">
                  <!-- Generales -->
                  <div v-if="mensajes.gen">
                    <div class="cabMsj">
                      <v-icon>{{ 'mdi-shield-home-outline' }}</v-icon>
                      <span class="cabText">{{ textCabGen }}</span>
                    </div>

                    <v-sheet style="margin-top:10px">
                      <!-- <span class="mensaje">{{ mensajes.gen}}</span> -->                      
                      <v-textarea 
                        class="mensaje" 
                        style="width:600px;border:0px"
                        v-model="mensajes.gen" 
                        v-bind="$input"
                        no-resize 
                        readonly 
                        auto-grow
                        rows="1"                        
                        flat>
                      </v-textarea>
                    </v-sheet>
                  </div>

                  <!-- Particulares -->  
                  <div v-if="mensajes.part" :class="mensajes.gen? 'separation' : ''">
                    <div class="cabMsj">
                      <v-icon>{{ 'mdi-shield-account-outline' }}</v-icon>
                      <span class="cabText">{{ textCabPart }}</span>
                    </div>
                    
                    <v-sheet style="margin-top:10px">
                      <v-textarea 
                        class="mensaje" 
                        style="width:600px;border:0px"
                        v-model="mensajes.part" 
                        v-bind="$textarea"
                        no-resize 
                        readonly 
                        auto-grow    
                        rows="1"
                        flat>
                      </v-textarea>
                      
                    </v-sheet>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </template> 
    </dualTemplate>
  </div>
</template>



<script>
  
  import baseHeader from "@/base/baseHeader";  
  import dualTemplate from "@/components/dualTemplate";
  
  export default {
    mixins: [],
    components: { baseHeader, dualTemplate },
    props: {
      mensajes: { type:Object, default:()=> {}}
    },

    data() {
      return {
        textCabGen: "GENERAL",
        textCabPart: "PARTICULAR para " + (this.$store.state['G'].USUDAT.name).toUpperCase()
      }
    },

  };
</script>


<style scoped>
  .cabMsj {
    display:flex;
    align-items: center;
  }

  .cabText {
    padding-left: 10px;
    font-size: 1rem;
    color:lightslategrey;
    font-weight: bold;
  }

  .cabPart {   
    font-size: 1.2rem;
  }

  .separation {
    margin-top: 30px;
  }

  .mensaje {
    font-size: 1rem;
    color:lightslategray;
  }
</style>
