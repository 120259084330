<template>
  <v-container id="view-login">
    <v-layout justify-right wrap>
      <v-flex xs12 md8>        
        <base_Login @onClick="event_login"></base_Login>
        
        <!-- Ventana de mensajes iniciales -->
        <component
          :is="componenteDinamico"
          :mensajes="mensajes"
          @onSalir="salirMsgView">
        </component>
      </v-flex>
    </v-layout>
  </v-container>
</template>



<script>

  import plugs from "@/common/general_plugs";
  import msgView from "@/components/msgView";
  const base_Login = () => plugs.groute("base_Login.vue", "base");

  export default {
    components: { base_Login, msgView },
    data() {
      return {
        modal: false,
        componenteDinamico: null,
        mensajes:{},
        ruta:''
      };
    },

    created() {      
      if (sessionStorage.getItem("login")) this.$router.push({ path: '/' });
    },


    methods: {

      // acciones de los botones del login
      event_login(accion, txt_user, txt_pass) {
        // compruebo si el navegador soporta Local Storage
        if (typeof Storage === "undefined") {
          this.$root.$alert.open("Local Storage no soportado", "error");
          return;
        }

        if (accion== 'user') {
          this.validate_usuario(txt_user);    
          return;
        }

        this.validate_password(txt_pass);
      },


      // compruebo si el usuario existe
      async validate_usuario(txt_user) {

        let apiArg= ["", "validateUser", {}, 'login'];
        let param= { apiArg, args:{ user:txt_user, app:this.$store.state.G.APP } };
        let apiResult = await this.$store.dispatch("apiCall", param);

        console.log('apiResul validate_usuario', param, apiResult);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        /* // chequeo usuario en la Api
        let args = {
          accion: "check_username",
          tipo: "login",
          api: "login",          
          fn_args: { 
            user: txt_user, 
            app: this.$store.state.G.APP          
          }
        };
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        */

        // el usuario es correcto
        if (!apiResult.sql.error) {
          // guardo el token de usuario
          sessionStorage.setItem("username", apiResult.r[0].token);
          return;
        }

        // elimino el token de usuario
        sessionStorage.removeItem("username");
      },


      // termino el login si las validaciones de usuario y contraseña son correctas
      async validate_password(txt_pass) {

        let apiArg= ["", "validatePassword", { user: sessionStorage.getItem("username"), pass: txt_pass }, 'login'];
        let param= { apiArg };
        let apiResult = await this.$store.dispatch("apiCall", param);

        console.log('apiResul validate_password', apiResult);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
       /*  // valido usuario y contraseña
        let args = {
          tipo: "login",
          api: "login",
          accion: "check_password",
          fn_args: {
            user: sessionStorage.getItem("username"), // token
            pass: txt_pass
          }
        };
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
         */

        // usuario y contraseña válidos
        if (!apiResult.sql.error) {
          
          sessionStorage.removeItem("username"); // elimino el token de usuario        
          await this.$store.commit("set_authSuccess", apiResult.r[0].token);

          // cargo datos iniciales
          await this.cargaInicial();

          // muestro mensajes iniciales
          this.msgIniciales();

          // obtengo los avisos de expedientes
          if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();
          return;
        }

        this.$store.commit("logout");
        this.$root.$alert.open('Usuario o contraseña invalidos', 'error');
      },


      async cargaInicial() {
        let apiResult= await this.$store.dispatch("ini/load_dataIni");
        this.ruta= '/';

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          this.ruta='login';          
        }        
      },
      

      msgIniciales() {        
        let gen= this.$store.state['G'].MSGGEN;
        let part= this.$store.state['G'].USUDAT.msg;

        if (!gen && !part) {
          this.componenteDinamico= null;
          this.mensajes = {};
          this.salirMsgView();
          return;
        }
        
        this.componenteDinamico= 'msgView';
        this.mensajes = { gen, part };
      },


      salirMsgView() {
        this.$router.push({ path: this.ruta });
      }
      
    }
  };
</script>
